<template>
  <section class="intro-images">
    <!-- About page header -->
    <div class="container" data-aos="fade-up" data-aos-duration="1500">
      <div class="row">
        <div class="col col1"></div>
        <div class="col-lg-6 col-10">
          <h1>Foto's</h1>
          <a class="d-flex justify-content-center" href="https://www.instagram.com/liefslune/" target="_blank">
            <img src="@/assets/img/Instagram-icon.png" alt="Instagram" />
            <p>
              Volg ons op Instagram! <br />
              <span>liefslune</span>
            </p>
          </a>
        </div>
        <div class="col"></div>
      </div>
    </div>
    <div class="waves">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 1919 147.568"
      >
        <path
          id="Intersection_16"
          data-name="Intersection 16"
          d="M59.948-3953.432v-97.177c26.559-12.49,60.384-23.023,98.93-23.023,94.683,0,143.43,63.553,217.853,63.553s148.343-68.88,206.915-63.553,99.738,63.553,161.8,63.553,71.029-48.5,141.825-44.332,114.686,68.7,190.618,65.78S1182.14-4091.177,1244-4100.276a65.514,65.514,0,0,1,9.422-.724h.155c52.531.059,56.357,71.462,121.357,75.972,68.737,4.769,60.769-75.248,144.607-75.248s86.951,90.2,172.517,90.2,93.424-90.2,184.274-90.2c49.578,0,82.725,22.633,102.616,44.154v102.69Z"
          transform="translate(-59.948 4101)"
          fill="#fff"
        />
      </svg>
    </div>
  </section>
  <section class="image-grid">
    <div class="container justify-content-center">
      <div class="row row row-cols-1 row-cols-md-2 row-cols-lg-3">
        <!-- Voeg hier nieuwe foto's toe! -->
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_4276.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_2481.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_4279.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_2209.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_4289.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_2566.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_2476.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_4366.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_4373.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_2544.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_4390.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_2478.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_4394.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/IMG_2556.jpg" />
        <img class="col" src="@/assets/img/wikkelsetjes/image3.jpg" />
      </div>
    </div>
  </section>
</template>

